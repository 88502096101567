import React, { useEffect, useRef, useState } from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NumbersBar from '../components/NumbersBar'

import "./Planning.css"

const PlanningTemplate = ({ data, pageContext }) => {
  let link = useRef(null)
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (window.location && window.location.hash && window.location.hash.indexOf('success') > -1) {
      setShowAlert(true);
    }
  }, [])

  return (
    <Layout noPro>
      <Seo
        title={`TripPlanR: Générez votre planning ${pageContext.variant} à ${pageContext.name}`}
      />
      <div className="hero heroPlanning">
        {!!showAlert && <div className="successAlert">Votre planning va vous être envoyé d'ici quelques secondes par email. Découvrez déjà comment l'améliorer ci-dessous.</div> }
        <div className="planningRow planningRowWithPhone">
          <div className="planningColumn">
            <h1>
              Envie d'aller plus loin à{" "}
              <span className="textOrange">{pageContext.name}</span>&nbsp;?
            </h1>
            <h2>Complétez votre planning ou demandez en un sur mesure.</h2>
            <p className="smallExplain">Choisissez la formule qui vous convient:</p>

            <div className="cardsRow">
              <a className="card" href={`https://gumroad.com/l/tpr${pageContext.name}?wanted=true&variant=${pageContext.variant}`}>
                <h2>Planning Premium</h2>
                <span className="priceCarDesc">
                  Téléchargez les bons plans partagés par les habitants de la région. Pour ne rien louper de votre destination de rêve.
                </span>
                <span className="priceCard">
                  <strong>9,99€</strong> seulement
                </span>

                <p>5 jours de bons plans</p>
              </a>

              <a className="card" href="https://calendly.com/tripplanr-b2c/45m-call-b2c">
                <h2>Planning sur mesure</h2>
                <span className="priceCarDesc">
                  Nous co-créons le planning avec les habitants de la région pour vous offrir un voyage inoubliable adapté à vos envies.
                </span>
                <span className="priceCard">Demandez un rendez-vous</span>
              </a>
            </div>

            <a
              className="gumroad-button hidden"
              target="_blank"
              rel="noreferrer"
              ref={link}
            >
              Découvrir le trip
            </a>
          </div>
        </div>
      </div>

      <NumbersBar />

    </Layout>
  )
}

export const pageQuery = graphql`
  query MdxBlogPost2($slugVariant: String) {
    preview: file(name: { eq: $slugVariant }) {
      childImageSharp {
        resize(width: 250) {
          src
        }
      }
    }
  }
`

export default PlanningTemplate
